.Contact__form {
	width: 255px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.Contact__form__input {
	width: 100%;
	height: 40px;
	padding: 1px 4px;
	margin-bottom: 1rem;
	box-shadow: var(--box-shadow);
	border-radius: 5px;
	font-size: var(--font-size-small);
	border: 0;
	outline-color: var(--color-purple-light);
}

.Contact__form__input:focus {
	
}

.Contact__form__button {
	width: 100px;
	height: 40px;
	color: var(--color-white);
	background-color: var(--color-purple-light);
	align-self: flex-start;
	box-shadow: var(--box-shadow);
	border-radius: 10px;
	font-size: var(--font-size-tiny);
	border: 0;
	cursor: pointer;
	outline-color: var(--color-white);
}

.Contact__form__button:hover {
	animation: var(--animation-hover-scale);
}

@media screen and (min-width: 500px) {
	.Contact__form {
		width: 400px;
	}
}

.contact__status {
	margin: 0;
	text-align: center;
	font-size: var(--font-size-tiny);
}

.contact__status--success {
	color: var(--color-success);
}

.contact__status--error {
	color: var(--color-error);
}