.proyect__modal__main {
	position: fixed;
	width: 288px;
  max-height: 100vh;
	height: auto;
	max-height: 100vh;
	top: 50vh;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--color-white);
	overflow: hidden;
  overflow-y: scroll;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
}

.proyect__modal__main::-webkit-scrollbar {
  display: none;
}

.proyect__modal__main__close {
	position: absolute;
	right: 1px;
	top: 1px;
	margin: 0;
	cursor: pointer;
}

.proyect__modal__main__img {
	width: 100%;
}

.proyect__modal__main__title {
	margin: 1rem 0;
	font-size: var(--font-size-small);
	color: var(--color-blue);
	text-align: center;
	padding: 0 0.5rem;
}

.proyect__modal__main__description {
	font-size: var(--font-size-tiny);
	color: var(--color-black);
	text-align: center;
	margin: 0;
	margin-bottom: 1rem;
	padding: 0 0.5rem;
}

.proyect__modal__main__links {
	margin: 0;
	margin-bottom: 1rem;
	padding: 0;
	padding: 0 0.5rem;
}

.proyect__modal__main__links_item {
	list-style: none;
	text-align: center;
}

.proyect__modal__main__links_item__link {
	text-decoration: none;
	color: var(--color-purple-light);
	font-family: var(--font-secondary);
	font-size: var(--font-size-tiny);
}