@import url(https://fonts.googleapis.com/css2?family=Oswald&family=Roboto+Slab&display=swap);
:root {
	--font-primary: 'Oswald';
	--font-secondary: 'Roboto Slab';
	--color-white: #FCFCFC;
	--color-black: #101010;
	--color-transparent-black: #10101088;
	--color-blue: #3170D0;
	--color-grey-light: #DEDDDD;
	--color-purple-light: #A923E8;
	--color-success: #3BC22F;
	--color-error: #C22F2F;
	--font-size-large: 80px;
	--font-size-big: 63px;
	--font-size-medium: 48px;
	--font-size-small: 32px;
	--font-size-tiny: 24px;
	--font-size-little: 12px;
	--media-movile-L: 500px;
	--box-shadow: 4px 5px 4px 0px #101010;
	--animation-hover-scale: hoverScale 0.5s forwards;
}

body {
	margin: 0;
  font-family: -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background: linear-gradient(180deg, #2B3843 0%, #2A4661 25%);
	background-repeat: no-repeat;
}

h1 h2 h3 h4 {
	font-family: 'Oswald';
	font-family: var(--font-primary);
}

p {
	font-family: 'Roboto Slab';
	font-family: var(--font-secondary);
}

.section__title {
	font-size: 32px;
	font-size: var(--font-size-small);
	text-align: center;
	color: #FCFCFC;
	color: var(--color-white);
	margin: 1rem 0;
}

.section__grid {
	display: grid;
	grid-template-columns: 1fr;
}

.modal__background {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #10101088;
	background-color: var(--color-transparent-black);
	z-index: 1;
}

@media screen and (min-width: 500px) {
	.section__title {
		font-size: 48px;
		font-size: var(--font-size-medium);
	}
}

@media screen and (min-width: 700px) {
	.section__grid {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (min-width: 1000px) {
	.section__grid {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@keyframes hoverScale {
	from {
		transform: scale(1);
	}

	to{
		transform: scale(1.05);
	}
}
.presentation {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.presentation__img {
	width: 200px;
	border-radius: 100%;
}

.presentation__name {
	font-family: var(--font-primary);
	font-size: 40px;
	margin: 1rem 0;
	color: var(--color-white);
}

.presentation__info {
	text-align: center;
	font-size: var(--font-size-tiny);
	color: var(--color-grey-light);
	margin: 0;
	margin-bottom: 1rem;
}

.presentation__social {
	max-width: 320px;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

@media (min-width: 400px) {
  .presentation__name {
    font-size: var(--font-size-medium);
  }
}

@media (min-width: 580px) {
  .presentation__name {
    font-size: var(--font-size-large);
  }
}
.KnowledgeCard {
	display: flex;
	height: 64px;
	width: 256px;
	align-items: center;
	justify-content: space-between;
	background-color: var(--color-white);
	border-radius: 5px;
	margin: 0 auto;
	margin-bottom: 1rem;
	box-shadow: var(--box-shadow)
}

.KnowledgeCard__title {
	color: var(--color-black);
	margin: 0;
	margin-left: 1rem;
}

.KnowledgeCard__container {
	width: 64px;
	height: 64px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.KnowledgeCard__img {
	width: 100%;
	height: 100%;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.proyect__modal__main {
	position: fixed;
	width: 288px;
  max-height: 100vh;
	height: auto;
	max-height: 100vh;
	top: 50vh;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--color-white);
	overflow: hidden;
  overflow-y: scroll;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
}

.proyect__modal__main::-webkit-scrollbar {
  display: none;
}

.proyect__modal__main__close {
	position: absolute;
	right: 1px;
	top: 1px;
	margin: 0;
	cursor: pointer;
}

.proyect__modal__main__img {
	width: 100%;
}

.proyect__modal__main__title {
	margin: 1rem 0;
	font-size: var(--font-size-small);
	color: var(--color-blue);
	text-align: center;
	padding: 0 0.5rem;
}

.proyect__modal__main__description {
	font-size: var(--font-size-tiny);
	color: var(--color-black);
	text-align: center;
	margin: 0;
	margin-bottom: 1rem;
	padding: 0 0.5rem;
}

.proyect__modal__main__links {
	margin: 0;
	margin-bottom: 1rem;
	padding: 0;
	padding: 0 0.5rem;
}

.proyect__modal__main__links_item {
	list-style: none;
	text-align: center;
}

.proyect__modal__main__links_item__link {
	text-decoration: none;
	color: var(--color-purple-light);
	font-family: var(--font-secondary);
	font-size: var(--font-size-tiny);
}
.ProyectCard {
	background-color: var(--color-white);
	width: 256px;
	margin: 0 auto;
	margin-bottom: 1rem;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
	cursor: pointer;
}

.ProyectCard:hover {
	animation: var(--animation-hover-scale);
}

.ProyectCard__img {
	width: 256px;
	height: 256px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.ProyectCard__name {
	color: var(--color-blue);
	font-size: var(--font-size-small);
	text-align: center;
	margin: 1rem 0;
}
.courses__modal__main {
	position: fixed;
	top: 50vh;
	left: 50%;
	width: 288px;
	transform: translate(-50%, -50%);
	overflow: hidden;
	border-radius: 5px;
}

.courses__modal__main__close {
	position: absolute;
	right: 1px;
	top: 1px;
	margin: 0;
	cursor: pointer;
}

.courses__modal__main__img {
	width: 100%;
}

@media screen and (min-width: 375px) {
	.courses__modal__main {
		width: 343px;
	}
}

@media screen and (min-width: 425px) {
	.courses__modal__main {
		width: 393px;
	}
}

@media screen and (min-width: 500px) {
	.courses__modal__main {
		width: 468px;
	}
}

@media screen and (min-width: 700px) {
	.courses__modal__main {
		width: 668px;
	}
}
.coursesCard {
	width: 100%;
	background-color: var(--color-white);
	margin-bottom: 1rem;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
	cursor: pointer;
}

.coursesCard:hover {
	animation: var(--animation-hover-scale);
}

.coursesCard__name {
	text-align: center;
	color: var(--color-black);
}
.courses__list {
	max-width: 720px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: space-between;
	align-items: center;
	max-height: 666px;
	overflow-y: auto;
	overflow-x: hidden;
}

.courses__list::-webkit-scrollbar {
	width: 4px;
}

.courses__list::-webkit-scrollbar-track {
	border-radius: 10px;
}

.courses__list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: var(--color-purple-light);
}

.courses__show {
	max-width: 720px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.courses__show__button {
	float: right;
	margin: 1rem 1rem 1rem 0;
	color: var(--color-purple-light);
	font-size: var(--font-size-tiny);
	cursor: pointer;
}

.courses__show__button:hover {
	animation: var(--animation-hover-scale);
}
.Contact__form {
	width: 255px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.Contact__form__input {
	width: 100%;
	height: 40px;
	padding: 1px 4px;
	margin-bottom: 1rem;
	box-shadow: var(--box-shadow);
	border-radius: 5px;
	font-size: var(--font-size-small);
	border: 0;
	outline-color: var(--color-purple-light);
}

.Contact__form__input:focus {
	
}

.Contact__form__button {
	width: 100px;
	height: 40px;
	color: var(--color-white);
	background-color: var(--color-purple-light);
	align-self: flex-start;
	box-shadow: var(--box-shadow);
	border-radius: 10px;
	font-size: var(--font-size-tiny);
	border: 0;
	cursor: pointer;
	outline-color: var(--color-white);
}

.Contact__form__button:hover {
	animation: var(--animation-hover-scale);
}

@media screen and (min-width: 500px) {
	.Contact__form {
		width: 400px;
	}
}

.contact__status {
	margin: 0;
	text-align: center;
	font-size: var(--font-size-tiny);
}

.contact__status--success {
	color: var(--color-success);
}

.contact__status--error {
	color: var(--color-error);
}
.footer {
	background-color: var(--color-white);
	width: auto;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
}

.footer__logos__container {
	display: flex;
}

.footer__social__logo {
	width: 25px;
}

.footer__info {
	font-size: var(--font-size-little);
	color: var(--color-black);
	margin: 0	;
}

@media (min-width: 500px) {
	.footer__info {
		font-size: var(--font-size-tiny);
	}
}

.footer__logos__container a:first-of-type {
  margin-right: 1rem;
}
main {
	max-width: 1000px;
	margin: 0 auto;
	padding: 1rem;
}

